import { initializeApp } from 'firebase/app';
import "firebase/firestore";
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDLA3cAmDB9aaktrpsL-oSNJRe1Pbp2qVQ",
  authDomain: "ifiwasgod-ad48c.firebaseapp.com",
  projectId: "ifiwasgod-ad48c",
  storageBucket: "ifiwasgod-ad48c.appspot.com",
  messagingSenderId: "368278613457",
  appId: "1:368278613457:web:ddebcf05c3eba9484404f0",
  measurementId: "G-H96GTSZQMT"
  };

firebase.initializeApp(firebaseConfig)

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app)
export const authentication = getAuth(app);