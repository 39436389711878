// App.js
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';
import './App.css';
// import Modal from './Modal';
import {Button,  Table, InputGroup, Modal, FloatingLabel} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import Form from 'react-bootstrap/Form';
import backgroundImage from './bg2.jpg';
import 'firebase/compat/firestore';
import { firestore } from './firebase';
import { collection, doc, addDoc, query, where, getDocs, getFirestore, getDoc, } from 'firebase/firestore';
import firebase from 'firebase/compat/app';
import mixpanel from 'mixpanel-browser';

// / create an instance of the mixpanel client
mixpanel.init("8ba119e5573bf04687aaa3e6b33961c8", {track_pageview: true});

// const emojis = ['😟', '😢', '😭', '😠', '😤', '😡', '🤬'];
const emojis = ['💔'];
// Initialize Cloud Firestore and get a reference to the service
const db = firebase.firestore();
let docid

function App() {
  const [progress, setProgress] = useState(0);
  const [count, setCount] = useState(0);
  const [failuresCount, setFailuresCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isButtonHeld, setIsButtonHeld] = useState(false);
  const [isButtonReleased, setIsButtonReleased] = useState(false);
  const [currentEmojiIndex, setCurrentEmojiIndex] = useState('❤️');
  const [show, setShow] = useState(false);
  const [finishShow, setFinishShow] = useState(false);
  const [verificationKey, setVerificationKey] = useState('');
  const [message, setMessage] = useState(null);
  const [messageDB, setMessageDB] = useState('');
  const [DocID, setDocID] = useState('');
  const [walk, setWalk] = useState(null);
  const [walkDB, setWalkDB] = useState(1);
  const [name, setName] = useState(null);
  const [senderName, setSenderName] = useState(null);
  const [key, setKey] = useState(null);
  const [recipientName, setRecipientName] = useState('');
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [clickDuration, setClickDuration] = useState(0);
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [navigatorOpened, setNavigatorOpened] = useState(false);
  const isWakeLockSupported = 'wakeLock' in navigator;
  const [showFirstMessage, setShowFirstMessage] = useState(true);
  const [smessage, setSmessage] = useState(null);
  const [swalk, setSwalk] = useState(null);
  const [rname, setRname] = useState(null);
  const [sname, setSname] = useState(null);
  const [failed, setFailed] = useState(8866);
  
  const messages = [
    "Send Unforgettable Messages",
    "Send Important Messages",
    "Send HBD Messages",
    "Send Mother's Day Messages",
    "Send Apology Messages",
    "Send Wedding Messages",
    "Send Congratulatory Messages",
    "Send Father's Day Messages",
    // "Messages exclusively for Cherished Connections",
    // "All Buttons are Easy to Click",
    // "Not this...",
    // `${failed} failed attempts`,
    // "But True Friends deserves the effort"
    // "All Buttons are Easy ...",
    // "Tried The Internet Hardest Button?",
    // `${failed} Failed Attempts ..?`,
    // `on Birthdays, Weddings, Mother's Day etc`,
    // "Only Important Friends Deserve"
  ];

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

  let wakeLock = null;


  const navigate = useNavigate();  // Use useNavigate instead of useHistory

  // useEffect(() => {
  //   // const intervalId = setInterval(async () => {
  //     try {
  //       db.collection('messages').doc("fails")
  //       .onSnapshot((doc) => {
  //         var fails = doc.data().fails;
  //         console.log("fails: ", fails)
  //         setFailed(fails)
  //       });
       
  //     } catch (error) {
  //       console.error('Error fetching document:', error);
  //     }
  //   // }, 10000); // Fetch data every 10 seconds * 10

  //   // Clean up the interval on component unmount
  //   // return () => clearInterval(intervalId);
  // }, [isButtonHeld]);


  const verifyShow = () => {
    // mixpanel.track('showUploadModal', {
    //   time: new Date().toLocaleString(),
    // })
    setShow(true)
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
    }, 5000); // Change message every 30 seconds

    return () => clearInterval(intervalId);
  }, []);

  // const finishShow = () => {
  //   setFinishShow(true)
  // };

  const verifyClose = () => (
    setShow(false),
    setProgress(0)
  );

  const finishDisplay = () => (
    setFinishShow(true)
  );

  const finishClose = () => (
    setFinishShow(false),
    // TODO: reset page
    window.location.reload()
  );

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowFirstMessage(false);
  //   }, 4000);

  //   // Clear timeout on unmount or when showFirstMessage becomes false
  //   return () => clearTimeout(timer);
  // }, []);

const handleVerifyClaim = async () => {
  // Add logic to verify claim using verificationKey
  // For now, just log the verificationKey to the console
  // console.log('Verification Key:', verificationKey);
  const docRef = await addDoc(collection(firestore, 'treasures'), {
    key: key,
    walk: walk,
    message: message
  });
  // Close the modal
  setShow(false);
};

const handleKeySearch = async () => {

  // const q = query(collection(db, "treasures"), where("key", "==", key));

  // const querySnapshot = await getDocs(q);
  // querySnapshot.forEach((doc) => {
  //   // doc.data() is never undefined for query doc snapshots
  //   console.log(doc.id, " => ", doc.data());
  //   docid = doc.id
  //   setDocID(doc.id)
  //   var walk_str = doc.data().walk
  //   var message = doc.data().message
  //   setWalkDB(parseInt(walk_str))
  //   setMessageDB(message)
  //   setSearchCompleted(true); // Set searchCompleted to true when search is completed
  //   // setMessage()
  // });
  
}

useEffect(() => {
  let progressInterval;
  let updateFirestoreInterval;

  if (isButtonHeld) {
    progressInterval = setInterval(() => {
      if (progress < 100) {
        setProgress((prev) => prev + 1);
        setClickDuration((prev) => prev + 1);
      } else {
        clearInterval(progressInterval);
        handleSuccess();
        // try {
        //   navigator.share({
        //     title: 'Visit www.hardestbutton.com',
        //     text: 'I have just won !!!',
        //     url: 'https://www.hardestbutton.com',
        //   });
        //   // setProgress(0)
        // } catch (error) {
        //   console.error('Error sharing:', error);
        //   // alert('use on mobile')
        // }
        // navigate('/message', { state: { message: messageDB } });
      }
    }, 10 * walk); // Update progress every second (1000 milliseconds)
   
    // updateFirestoreInterval = setInterval(() => {
    //   console.log("docid: ", docid)
    //   console.log("clickDuration: ", clickDuration)
    //   firestore.collection('treasures').doc(docid).update({
    //     player1: clickDuration,
    //   })
    //   .then(() => {
    //     console.log('Firestore updated successfully');
    //   })
    //   .catch((error) => {
    //     console.error('Error updating Firestore:', error);
    //   });
    // }, 5000); // Update Firestore every 30 seconds (30000 milliseconds)
  }

  return () => {
    clearInterval(progressInterval);
    clearInterval(updateFirestoreInterval);
  };
}, [isButtonHeld, clickDuration, messageDB, walk]);


  const handleButtonHold = async () => {
   
    if (message == null || senderName == null || name == null || walk == null) {
      mixpanel.track('loveButtonWithoutFilling', {
        message: message,
        senderName: senderName,
        name: name,
        walk: walk
      })
      alert('Please fill all info...')
      return;
    }
    mixpanel.track('loveButton', {
      message: message,
      senderName: senderName,
      name: name,
      walk: walk
    })
    // Set the time it takes for progress to reach 100 based on the value entered
    const timeInSeconds = walk * 60 * 1000; // Convert minutes to milliseconds
    setIsButtonHeld(true);
    setIsButtonReleased(false);
    setCurrentEmojiIndex(0);
    setProgress(0);

    if (isWakeLockSupported) {
      wakeLock = await navigator.wakeLock.request('screen');
      console.log('Screen wake lock activated');
    }

    if (progress >= 100){
        clearInterval();
        setIsButtonHeld(false);
        handleSuccess();
        //  console.log('i see you')
        // navigate('/message', { state: { message: messageDB } }); 
        return;
      }

    // Start the progress interval
  // const progressInterval = setInterval(() => {
  //   if (progress < 100) {
  //     setProgress((prev) => prev + (100 / timeInSeconds)); // Increment progress based on time
  //   } else {
  //     clearInterval(progressInterval);
  //     handleSuccess();
  //     navigate('/message', { state: { message: messageDB } });
  //   }
  // }, 100); // Update progress every 100 milliseconds


  // // Set timeout to handle the case where the button is held longer than the specified time
  // setTimeout(() => {
  //   clearInterval(progressInterval);
  //   if (progress < 100) {
  //     handleButtonRelease(); // Trigger button release if progress is less than 100 after the specified time
  //   }
  // }, timeInSeconds);

  // // If progress reaches 100, trigger handleSuccess
  // if (progress === 100) {
  //   clearInterval(progressInterval);
  //   handleSuccess();
  //   navigate('/message', { state: { message: messageDB } });
  // }

  };

  const handleButtonRelease = () => {
    clearInterval();
    setIsButtonHeld(false);
    setIsButtonReleased(true);
    setProgress(0);
    setClickDuration(0)
    // setCurrentEmojiIndex(0);
    


    if (progress < 100 && count < 1){
      db.collection('messages').doc('fails').update({
        fails: firebase.firestore.FieldValue.increment(1),
        })
    }

    // if (progress < 100 && count == 1){
    //   db.collection('messages').doc('fails').update({
    //     fails: firebase.firestore.FieldValue.increment(1),
    //     })
    // }

    if (count > 0) {
      setProgress(0);
      resetProgress()
      setClickDuration(0)
    //   // Display 'you lose' message
      // handleFailure();
    //   // Display 'you lose' message only if the button is released before 1 minute
      // setIsButtonReleased(false);
    //   // console.log('i no see you')
    //   // console.log('progress: ', progress)
    //   //  if (count > 0) {
    //   //   setProgress(0);
    //   //  handleSuccess();
    //   //  console.log('i see you')
    //   //  navigate('/message', { state: { message: messageDB } });  // Use navigate instead of history.push
    //   //  }
    //   //  else {
    //   //   // If the button is released before 1 minute and count is still 0
    //   //   // if (currentEmojiIndex < emojis.length - 1) {
    //   //   //   setCurrentEmojiIndex((prevIndex) => prevIndex + 1);
    //   //   // }
    //   //   // If the button is released before 1 minute and count is still 0
    //   //   // / If the button is released before 1 minute and count is still 0
    //   //   setFailuresCount((prevCount) => prevCount + 1);
    //   //   setCurrentEmojiIndex(failuresCount % emojis.length);
    //   // }

    }
    // 
    // else {
    //   handleSuccess();
    //   //  console.log('i see you')
    //    navigate('/message', { state: { message: messageDB } }); 
    // }

    if (wakeLock !== null) {
      wakeLock.release()
        .then(() => {
          console.log('Screen wake lock released');
        })
        .catch((error) => {
          console.error('Failed to release screen wake lock:', error);
        });
    }
    
  };

  const handleSuccess = async () => {
    clearInterval();
    setCount((prev) => prev + 1);
    // setShowModal(true);
    setFailuresCount(0);
    resetProgress();
    // Navigate to the MessagePage
    // navigate('/message');  // Use navigate instead of history.push
    // console.log("senderName: ", senderName)
    // console.log("message: ", message)
    setSname(senderName)
    setRname(name)
    setSmessage(message)
    setSwalk(walk)

    const currentTime = new Date();
    const hours = String(currentTime.getHours()).padStart(2, '0'); // Ensure two digits
    const minutes = String(currentTime.getMinutes()).padStart(2, '0'); // Ensure two digits
    const seconds = String(currentTime.getSeconds()).padStart(2, '0'); // Ensure two digits

    const timeInNumbers = hours + minutes + seconds;
    // console.log(`Current time in numbers: ${timeInNumbers}`);
    try {
     
      await db.collection('messages').doc('success').update({
        kings: firebase.firestore.FieldValue.arrayUnion(senderName +'-'+ timeInNumbers),
        queens: firebase.firestore.FieldValue.arrayUnion(name +'-'+ timeInNumbers),
        message: firebase.firestore.FieldValue.arrayUnion(message +'-'+ timeInNumbers),
        difficulty: firebase.firestore.FieldValue.arrayUnion(walk +'-'+ timeInNumbers),
        })
        // setNavigatorOpened(true);
        // navigator.share({
        //   title: `${senderName} cares`,
        //   text: sharedMessage,
        //   url: 'https://www.hardestbutton.com',
        // });
        .then(() => {
            console.log('Success added to holders array.');
            finishDisplay()
        })
        .catch((error) => {
            console.error('Error adding success:', error);
        });
     
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  const handleShareButton = () => {
    // setName(null)
    // setMessage(null)
    // setWalk(null)
    // setSenderName(null)

    try{
      // const sharedMessage = `${senderName} held the hardest button on the internet for ${walk} seconds non-stop, just to send this to you: \n\n${message}.\n`;
      const sharedMessage = `For ${walk} seconds non-stop, ${senderName} held the hardest button on the internet just to send you this message: \n\n${message}.\n`;
      navigator.share({
        // title: `${senderName} cares`,
        text: capitalizeFirstLetterOfEachWord(sharedMessage) + '\n',
        url: 'https://www.hardestbutton.com',
      });
    }catch(error){
      console.error('Error sharing:', error);
    }
    
  }

      // Function to capitalize each word in a string
    const capitalizeFirstLetterOfEachWord = (str) => {
      return str.replace(/\b\w/g, (char) => char.toUpperCase());
    }

  // const addTen = async () => {
  //   const q = query(collection(db, "treasures"), where("key", "==", key));
  //   const querySnapshot = await getDocs(q);
  //   querySnapshot.forEach((doc) => {
  //     // doc.data() is never undefined for query doc snapshots
  //     // console.log(doc.id, " => ", doc.data());
  //     docid = doc.id
  //     setDocID(doc.id)
  //   });
  //     var userRef = db.collection("treasures").doc(docid);
  //     userRef.update({
  //       //subtract the amount from expecting.
  //       additional: firebase.firestore.FieldValue.increment(+10),
  //       });
  // }

  // const minusTen = async () => {
    
  //   const q = query(collection(db, "treasures"), where("key", "==", key));
  //   const querySnapshot = await getDocs(q);
  //   querySnapshot.forEach((doc) => {
  //     // doc.data() is never undefined for query doc snapshots
  //     // console.log(doc.id, " => ", doc.data());
  //     docid = doc.id
  //     setDocID(doc.id)
  //   });
  //     var userRef = db.collection("treasures").doc(docid);
  //     userRef.update({
  //       //subtract the amount from expecting.
  //       additional: firebase.firestore.FieldValue.increment(-10),
  //       });
  // }

  const resetProgress = () => {
    setProgress(0);
  };

  const closeModal = () => {
    setShowModal(false);
    setProgress(0);
  };

  // const handleFailure = () => {
  //   setShowModal(false);
  //   resetProgress();
  //   // Display "You lose" message
  //   if (isButtonReleased) {
  //     // Display "You lose" message
  //     return <div className="center-message">You lose</div>;
  //   }
  // };

  useEffect(() => {
    const fetchLeaderboardData = async () => {
      const db = getFirestore();
      // Fetch data from the 'success' document within the 'messages' collection
      const successRef = doc(collection(db, 'messages'), 'success');
      const successSnapshot = await getDoc(successRef);
      const successData = successSnapshot.data();

      // Process the successData to get the required fields
      const leaderboardEntries = successData.kings.map((king, index) => ({
        from: king.split('-')[0], // Extract senderName from 'kings'
        to: successData.queens[index].split('-')[0], // Extract name from 'queens'
        difficulty: successData.difficulty[index].split('-')[0], // Extract walk from 'difficulty'
      }));

      // Sort the entries based on difficulty (highest to lowest)
      leaderboardEntries.sort((a, b) => b.difficulty - a.difficulty);

      setLeaderboardData(leaderboardEntries);
    };

    fetchLeaderboardData();
  }, []);

  return (

    <div className="App" style={{ fontFamily: 'Young Serif', alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'column',   }}>
      <div style={{ flex: 1 }} class="container" >
      <nav bg="dark" class="navbar navbar-expand navbar-dark" style={{"padding":"0px", margin:0}} >
        {/* <a href="https://www.hardestbutton.com" class="navbar-brand" style={{"color": '#CDB3B3', margin:5}} > hardestbutton.com  </a>  */}
{/*         
        <button
          class="navbar-toggler"
          type ="button"
          data-bs-toggle="collapse"
          data-bs-target="#toggleMobileMenu"
          aria-controls="toggleMobileMenu"
          aria-expanded="false"
          aria-label="Toggle navigation"
          >
          <span class="navbar-toggler-icon"></span>
        </button> */}
        {/* <div class="collapse navbar-collapse" id="toggleMobileMenu">
          <ul class="navbar-nav">
          
          </ul>
        </div> */}
         <div onClick={verifyShow} style={{ color: "#BFBAB2", marginBottom:30, marginTop:10}} class="collapse navbar-collapse" id="toggleMobileMenu">
          <ul class="navbar-nav">
          <a href="#" onClick={verifyShow} style={{ color: '#5E5E5E', cursor: "pointer" }}>
          <img src="./board.png" width="30" 
            onContextMenu={(e) => e.preventDefault()}
          />
          </a>
          </ul>
        </div> 
        {/* <div class="collapse navbar-collapse" id="toggleMobileMenu">
          <ul class="navbar-nav">
          <a href="#" onClick={'handleShow'} style={{ color: '#5E5E5E', cursor: "pointer" }}>
          <img src="./lb.png" width="30" />
          </a>
          </ul>
        </div> */}
        {/* <div class="collapse navbar-collapse" id="toggleMobileMenu">
          <ul class="navbar-nav">
          </ul>
        </div>
        <div style={{align:'right'}}class="collapse navbar-collapse" id="toggleMobileMenu">
          <ul class="navbar-nav">
          </ul>
        </div> */}
        {/* <Button onClick={toggleDarkMode}>Upload</Button> */}
      
        {/* <div class="collapse navbar-collapse" id="toggleMobileMenu">
          <ul class="navbar-nav">
          Upload
          </ul>
        </div> */}
        <span class="navbar-text">
          {/* <Form className="dark-mode-toggle" style={{ borderColor: darkMode ? 'dark-mode' : '' , color :color}}>
          <Form.Check 
          reverse='True'
            type="switch"
            id="darkModeSwitch"
            onChange={toggleDarkMode}
            label=""
          />
        </Form>  */}
        </span>
      </nav>
    </div>

<Form style={{ width: '80%'}}>
    {/* <div>
    {showFirstMessage && (
        <div className="first-message">
          <a style={{ color: '#CDB3B3', fontSize: '35px', fontWeight: 900, textAlign: 'center', width: '80%' }}>Love is the hardest button</a>
        </div>
      )}
      {!showFirstMessage && (
        <div className="second-message">
          <a style={{ color: '#CDB3B3', fontSize: '35px', fontWeight: 900, textAlign: 'center', width: '80%' }}>Send unforgettable messages</a>
        </div>
      )}
    </div> */}
    <div>
   
       {/* <div className="first-message"> */}
        {/* <a style={{ color: '#CDB3B3', fontSize: '35px', fontWeight: 900, textAlign: 'center', width: '80%' }}>Send Unforgettable Messages</a>
         <a style={{ color: '#CDB3B3', fontSize: '35px', fontWeight: 900, textAlign: 'center', width: '80%' }}> Messages exclusively for Cherished Connections</a>  */}
          {/* <a style={{ color: '#CDB3B3', fontSize: '35px', fontWeight: 900, textAlign: 'center', width: '80%' }}> Exclusive DMs for VIPs</a> */}
        {/* </div>  */}
{/* 
        <div className="first-message" style={{ justifyContent: 'center', textAlign: 'center',  alignItems: 'center', width: '90%' }}>
         <a style={{color: '#CDB3B3', fontSize: '35px', fontWeight: 900, justifyContent: 'center',}}>
           {messages[currentMessageIndex]}
         </a>
       </div> */}
       {/* #CDB3B3 */}
        <div className="first-message" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '10vh'}}>
          <a style={{ color: '#935D5D', fontSize: '26px', fontWeight: 900, textAlign: 'center', width: '80%', minWidth: '200px', marginBottom:10 }}>
            {messages[currentMessageIndex]}
          </a>
        </div>
      
      {/* {!showFirstMessage && (
        <div className="second-message">
          <a style={{ color: '#CDB3B3', fontSize: '35px', fontWeight: 900, textAlign: 'center', width: '80%' }}>Send unforgettable messages</a>
        </div>
      )} */}
    </div>

    <Form 
    style={{ marginTop: 25}}
    // style={{ width: '80%', marginBottom: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    >
        <FloatingLabel
        controlId="floatingInput"
        label="from ?"
        md="4"
      >
        <Form.Control style={{ margin:2, textTransform: "capitalize"}} type="name" placeholder="from ? " 
         value={senderName}
         onChange={(e) => setSenderName(e.target.value)} />
      </FloatingLabel>

        {/* <Form.Group  md="4" controlId="formBasicEmail">  */}
          {/* <Form.Label>Receiver username</Form.Label>  */}
          {/* <Form.Control style={{ margin:2,textTransform: "capitalize"}} type="name" placeholder="from ? " 
         value={senderName}
         onChange={(e) => setSenderName(e.target.value)}
         />  */}
  
       {/* </Form.Group> */}
        {/* <Form.Group md="4" controlId="formBasicEmail">   */}
          {/* <Form.Label>Receiver username</Form.Label>  */}
          {/* <Form.Control style={{ margin:2, textTransform: "capitalize"}} type="name" placeholder="to ? " 
         value={name}
         onChange={(e) => setName(e.target.value)}
         />   */}
  
       {/* </Form.Group> */}
       <FloatingLabel
        controlId="floatingInput"
        label="to ?"
        md="4"
        variant="dark"
      >
        <Form.Control style={{ margin:2, textTransform: "capitalize"}} type="name" placeholder="to ? " 
         value={name}
         onChange={(e) => setName(e.target.value)} />
      </FloatingLabel>
      <Form.Text style={{ fontSize:12 }} className="text-muted">
          use Names instead of Emails or Numbers.
      </Form.Text>
    </Form>
    {/* <Form > */}
      <FloatingLabel
          controlId="floatingTextarea"
          label="love, gratitude, hbd message etc..."
          md="4"
        >
        <Form.Control style={{ marginTop:4, marginBottom:4, textTransform: "capitalize", borderRadius: 25}} as="textarea" rows={3} placeholder="love, gratitude, hbd message etc..." value={message} onChange={(e) => setMessage(e.target.value)} />
      </FloatingLabel>
       {/* <Form.Text style={{ fontSize:12 }} className="text-muted">
          write your message
      </Form.Text> */}
      {/* <Form.Group md="4" controlId="exampleForm.ControlTextarea1">
        <Form.Control style={{ textTransform: "capitalize", borderRadius: 25, margin:2 }} as="textarea" rows={3} placeholder="love, gratitude, apology messages etc" value={message} onChange={(e) => setMessage(e.target.value)} />
      </Form.Group> */}
    {/* </Form> */}

    {/* <Form.Group md="4" controlId="formBasicEmail"> */}
              {/* <Form.Label>difficulty? </Form.Label>  */}
        {/* <Form.Control style={{margin:2}} type="number" placeholder="How Hard? (in Seconds)" value={walk} onChange={
          // TODO: alert if input is not numbers
          // caclulate the amount of wait time
          (e) => {
           // Ensure the value is not negative
            // let newValue = Math.max(parseFloat(e.target.value), 0.01);
            // // Ensure the value is rounded to two decimal places
            // newValue = Math.round(newValue * 100) / 100;
            // // Update the state with the new value
            setWalk(e.target.value);
        }
        }/> */}
    {/* </Form.Group> */}

    <FloatingLabel
        controlId="floatingInput"
        label="how hard? (in seconds)"
        md="4"
      >
        <Form.Control style={{margin:2}} type="number" placeholder="how hard? (in seconds)" value={walk} onChange={
          // TODO: alert if input is not numbers
          // caclulate the amount of wait time
          (e) => {
           // Ensure the value is not negative
            // let newValue = Math.max(parseFloat(e.target.value), 0.01);
            // // Ensure the value is rounded to two decimal places
            // newValue = Math.round(newValue * 100) / 100;
            // // Update the state with the new value
            setWalk(e.target.value);
        }
        } />
      </FloatingLabel>

    </Form>       
      
          {/* <InputGroup hasValidation style={{ width: '95%' }}>
            <Form.Control
              type="text"
              autoComplete="off"
              placeholder="paste verification key..."
              aria-describedby="inputGroupPrepend"
              // onChange={getData}
              required
              style={{ marginTop: 20, borderRadius:50, height: 75}}
              // onKeyDown={searchTags}
              // variant="outline-dark"
            />
            <Form.Control.Feedback type="invalid">
              Please input a search word or phrase.
            </Form.Control.Feedback>
          </InputGroup> */}

      {/* <Form style={{fontFamily: 'Lexend Zetta', height: 45, margin: 5, marginTop: 0, borderRadius:50, width: '80%'}} variant="outline-dark"> */}
        {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1"> */}
          {/* <Form.Label>Who's pressing your buttons?</Form.Label> */}
          {/* <Form.Control type="text" value={key} */}
        {/* onChange={(e) => setKey(e.target.value)}  */}
        {/* onBlur={handleKeySearch} 
        style={{height: 60, 
        // textTransform: "capitalize"
        }} placeholder="location ?" />
        <Form.Text className="text-muted">
          Input location & wait for Activation.
        </Form.Text> */}
        {/* </Form.Group> */}
      {/* </Form> */}
      
      {/* <div className="progress-bar-container" style={{ width: '80%' }}> */}
        {/* <div className="progress-bar" style={{ width: `${progress}%` }}></div> */}
        {!isButtonReleased && count < 1 && 
          <div onContextMenu={(e) => e.preventDefault()} style={{ fontFamily: 'Young Serif', fontSize: '100px', fontWeight: 800, textAlign: 'center', color:'#5E5E5E' }} className="center-message">
            ❤️
          </div>
        }
        {isButtonReleased && count === 1 && 
          <div onContextMenu={(e) => e.preventDefault()} style={{ fontFamily: 'Young Serif', fontSize: '100px', fontWeight: 800, textAlign: 'center', color:'#5E5E5E' }} className="center-message">
            ❤️
          </div>
        }
        
        {isButtonReleased && count < 1 && 
        <div onContextMenu={(e) => e.preventDefault()} style={{ fontFamily: 'Young Serif', fontSize: '100px', fontWeight: 800, textAlign: 'center', color:'#5E5E5E' }} className="center-message">
        {/* L o s t ! */}
        {emojis[currentEmojiIndex]}
        </div>}
      {/* </div> */}
      {/* {count > -1 && <div style={{ fontFamily: 'Young Serif', fontSize: '100px', fontWeight: 800, textAlign: 'center', color:'#ABABAB', opacity: count > -1 ? 0 : 1 }} className="count">{count}</div>} */}
      {/* <div style={{ marginBottom: '10px' }}> */}
        {/* <Form.Text className="text-muted" style={{ fontStyle: 'italic' }}> 
          4 successes / 116 attempts
        </Form.Text> */}
      {/* </div>    */}

      <input style={{  borderRadius:30, 
      // marginTop: 5,
      height: 25, 
      width: '90%',
      appearance: 'none',
      marginBottom:5
      // background: `linear-gradient(to right, red 0%, red ${progress * 20}%, #CED4DA ${progress * 20}%, #CED4DA 100%)`
      }} 
      // variant="outline-dark"
        type="range"
        min="0"
        max="100"
        value={progress}
        // onChange={(e) => setProgress(Number(e.target.value))}
      />
      <Button style={{ margin:5, fontFamily: 'Lexend Deca', fontSize: '20px', fontWeight: 80, borderRadius: 5, height: 80, width: '90%', backgroundColor: isButtonHeld ? '#8C0404' : '#000000'}}
        variant="light"
        className="big-button"
        onMouseDown={handleButtonHold}
        onMouseUp={handleButtonRelease}
        onTouchStart={handleButtonHold}
        onTouchEnd={handleButtonRelease}
        // onClick={handleShareButton}
        // disabled={!searchCompleted}
        onContextMenu={(e) => e.preventDefault()}
      >
        {/* C l i c k  */}
        
        <img src="./love.png" width="50" style={{ transform: 'rotate(380deg)' }}
        onContextMenu={(e) => e.preventDefault()}
        />

      </Button>
      
      {/* <div style={{ fontFamily: 'Young Serif', fontSize: '20px', fontWeight: 800, textAlign: 'center', color:'#5E5E5E', margin:10 }}>
        <Form.Text className="text-muted"> 
         Send DMs with a Splash of Passion
        </Form.Text>
      </div>  */}
      {/* <div style={{ fontFamily: 'Young Serif', fontSize: '100px', fontWeight: 800, textAlign: 'center', color:'#C1C1C1' }} className="center-message">
       <h6> Send DMs with a Splash of Passion </h6> 
        </div> */}
          
        {/* <div style={{ display: 'inline-block', flexDirection: 'row', alignItems: "center", justifyContent: "space-between", marginBottom: '50px' }}> */}
          {/* <Button onClick={addTen} style={{  marginRight:'10px', height:'60px', backgroundColor: '#FFFBFB' }} */}
          {/* variant="light"> */}
          {/* <img src="./before.png" width="20" style={{ transform: 'rotate(360deg)', marginRight: 5}}
        onContextMenu={(e) => e.preventDefault()}/> */}
             {/* + 10 secs */}
          {/* </Button> */}

          {/* <Button onClick={minusTen} style={{height:'60px', backgroundColor: '#FFFBFB'}} */}
          {/* variant="light"> */}
          {/* <img src="./after.png" width="20" style={{ transform: 'rotate(360deg)', marginRight: 5 }}
        onContextMenu={(e) => e.preventDefault()}/> */}
             {/* - 10 secs */}
          {/* </Button> */}
        {/* </div> */}
        

        

        <Modal show={show} onHide={verifyClose}>
        <Modal.Header closeButton>
          <Modal.Title>
          {/* <img src="./leaderboard.png" width="20" /> <small class="text-muted"> {tag_global} Dispute Claim?<h5> <b style={{ color: "#372502"}}>  </b>  </h5> </small> */}
          🏅
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <Table striped bordered hover>
        <thead>
          <tr>
            <th>🏅</th>
            <th>From</th>
            <th>To</th>
            <th>Difficulty (Secs)</th>
          </tr>
        </thead>
        <tbody>
          {leaderboardData.map((data, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{data.from}</td>
              <td>{data.to}</td>
              <td>{data.difficulty}</td>
            </tr>
          ))}
        </tbody>
    </Table>
        </div>
        
  
       
     
        </Modal.Body>
       
        {/* <Modal.Footer> */}
        <div class="row">
          <div class="col text-center">
              {/* <Button style={{ margin: 5, align: "center", fontSize:25, marginTop: 20, marginBottom: 35, width: 120, fontFamily: 'Lexend Deca', fontSize: '20px', fontWeight: 80, borderRadius: 5, height: 70, backgroundColor:'#DC4646', color:'#FFFFFF'}} variant="light" onClick={handleVerifyClaim}> */}
              {/* style={{ margin:5, fontFamily: 'Lexend Deca', fontSize: '20px', fontWeight: 80, borderRadius: 5, height: 80, width: '90%', backgroundColor:'#DC4646', color:'#FFFFFF'}} */}
                {/* <img src="./location.png" width="30" /> */}
                {/* Verify */}
              {/* </Button> */}

              {/* <ListGroup className="list-group-flush"> */}
                  {/* <ListGroup.Item > <div style={{ color: "#34332B", fontSize:15}}> Contributed by <b>{nick_name}</b> </div></ListGroup.Item> */}
                  {/* <ListGroup.Item>Last Transfered:	3 March 2023 / 23:19:50</ListGroup.Item> */}
                  {/* <ListGroup.Item>Vestibulum at eros</ListGroup.Item> */}
              {/* </ListGroup> */}
          </div>
        </div>
  
        {/* </Modal.Footer> */}
      </Modal>


      <Modal show={finishShow} onHide={finishClose}>
        <Modal.Header closeButton>
          <Modal.Title>
         You've got Heart!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Button
            variant="light"
            style={{ margin:5, fontFamily: 'Lexend Deca', fontSize: '20px', fontWeight: 80, borderRadius: 5, height: 80, width: '90%', backgroundColor: '#DC4646',}}
              onClick={handleShareButton}>
            <img src="./fit.png" width="50" style={{ transform: 'rotate(330deg)' }}/>
            </Button>
        </Modal.Body>
       
      </Modal>



    </div>

    // <div className="App" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
    //   <div className="progress-bar-container">
    //     <div className="progress-bar" style={{ width: `${progress}%` }}></div>
    //     {isButtonReleased && count < 1 && <div style={{ fontFamily: 'Young Serif', fontSize: '30px', fontWeight: 800, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: 'auto', marginTop: 30, width: '80%', color:'#5E5E5E' }} className="center-message"> L o s t !</div>}
    //   </div>
    //   {count > -1 && <div style={{ fontFamily: 'Young Serif', fontSize: '100px', fontWeight: 800, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: 'auto', marginTop: "50%", width: '80%', color:'#ABABAB' }} className="count">{count}</div>}
    //   {/* {showModal && <Modal closeModal={closeModal} />} */}   
    //   <div>
    //    <Form.Text className="text-muted"> 
    //       4 Successes / 27 Attempts
    //    </Form.Text>
    //   </div>   
    //   <Button style={{ fontFamily: 'Lexend Deca', fontSize: '20px', fontWeight: 80, marginTop: 'auto', borderRadius: 15, height: 80, width: '90%', margin: 30, backgroundColor:'#000000', color:'#FFFFFF'}}
    //     variant="light"
    //     className="big-button"
    //     onMouseDown={handleButtonHold}
    //     onMouseUp={handleButtonRelease}
    //     // onMouseLeave={handleButtonRelease}
    //     onMouseLeave={handleButtonRelease}
    //     onTouchStart={handleButtonHold}
    //     onTouchEnd={handleButtonRelease}
    //   >
    //     C l i c k 
    //     {/* <img src="./fit.png" width="35" color='#D80000'/> */}
    //   </Button>
     
    // </div>
  );
}

export default App;
