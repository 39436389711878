// MessagePage.js
import React, { useState, useEffect  } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import backgroundImage from './bg2.jpg';
import { useLocation } from 'react-router-dom';



const MessagePage = () => {
  const location = useLocation();
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [recipientName, setRecipientName] = useState('');

  // Retrieve messageDB from location state
  const messageDB = location.state && location.state.message;
  
  useEffect(() => {
    // Log the messageDB value to ensure it's retrieved correctly
    console.log('messageDB:', messageDB);
  }, [messageDB]);

  console.log('messageDB: ', messageDB)

  const handleShare = async () => {
    try {
      await navigator.share({
        title: 'Visit www.hardestbutton.com',
        text: 'I have just won !!!',
        url: 'https://www.hardestbutton.com',
      });
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

//   const handleShare = () => {
//     // Handle logic to share the message
//     console.log('Message:', message);
//     console.log('Email:', email);
//     console.log('Name:', name);
//     console.log('Recipient Name:', recipientName);
//     // Add your logic for sharing the message
//   };

  return (
    <div className="message-page" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    <h2 style={{ fontFamily: 'Young Serif', fontSize: '30px', fontWeight: 800, width: '80%', textAlign: 'center', color: '#5E5E5E' }}>Congratulations !</h2>
    {/* <Form 
    style={{ width: '80%', margin:5}}
    // style={{ width: '80%', marginBottom: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
    > */}

      {/* <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Control type="email" placeholder="from?" value={email} onChange={(e) => setEmail(e.target.value)} />
      </Form.Group> */}

        {/* <Form.Group className="mb-3" controlId="formBasicEmail">  */}
          {/* <Form.Label>Receiver username</Form.Label>  */}
          {/* <Form.Control type="name" placeholder="to?"  */}
         {/* value={recipientName} */}
         {/* onChange={(e) => setRecipientName(e.target.value)} */}
         {/* />  */}
  
       {/* </Form.Group> */}
    {/* </Form> */}
    <Form style={{ width: '80%'}}>
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Control style={{ borderRadius: 25 , marginTop:10}} as="textarea" rows={5} placeholder="What's on your mind..." value={messageDB} onChange={(e) => setMessage(e.target.value)} />
      </Form.Group>
    </Form>
   

    <Button
      style={{ fontFamily: 'Lexend Deca', fontSize: '25px', fontWeight: 80, marginBottom: '10%', marginTop: 35,borderRadius: 15, height: 80, width: '90%', backgroundColor: '#DC4646', color: '#FFFFFF' }}
      variant="light"
      onClick={handleShare}>
      {/* S e n d */}
        <img src="./cup.png" width="50" 
        style={{ transform: 'rotate(380deg)' }}

        />
    </Button>
  </div>
    // <div className="message-page" style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover', minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
    //   <h2 style={{ fontFamily: 'Young Serif', fontSize: '30px', fontWeight: 800, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', margin: 'auto', marginTop: 30, width: '80%', color:'#5E5E5E' }}>What's on your mind?</h2>
    //   {/* <textarea
    //     placeholder="Write your message here..."
    //     value={message}
    //     onChange={(e) => setMessage(e.target.value)}
    //   ></textarea> */}
    //   <Form style={{ width: '80%', marginBottom:"50%"}}>
    //   <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
    //     {/* <Form.Label>Write your message here...</Form.Label> */}
    //     <Form.Control style={{borderRadius: 25,}} as="textarea" rows={3} placeholder="express yourself..." 
    //         value={message}
    //     onChange={(e) => setMessage(e.target.value)}
    //     />
    //   </Form.Group>
    
    //   <Form.Group className="mb-3" controlId="formBasicEmail"> 
    //     {/* <Form.Label>Your Email</Form.Label> */}
    //     <Form.Control type="email" placeholder="your email?" 
    //     value={email}
    //     onChange={(e) => setEmail(e.target.value)}
    //     />
    //     {/* <Form.Text className="text-muted">
    //       We'll never share your email with anyone else.
    //     </Form.Text> */}
    //     </Form.Group>
      
    //   {/* <Form.Group className="mb-3" controlId="formBasicEmail"> */}
    //     {/* <Form.Label>Your username</Form.Label> */}
    //     {/* <Form.Control type="name" placeholder="Enter name (optional)" 
    //     value={name}
    //     onChange={(e) => setName(e.target.value)}
    //     /> */}
    //     {/* <Form.Text className="text-muted"> */}
    //       {/* We'll never share your email with anyone else. */}
    //     {/* </Form.Text> */}
    //   {/* </Form.Group> */}
    
    //   {/* <Form.Group className="mb-3" controlId="formBasicEmail"> */}
    //     {/* <Form.Label>Receiver username</Form.Label> */}
    //     {/* <Form.Control type="name" placeholder="Receiver username? (optional)" 
    //     value={recipientName}
    //     onChange={(e) => setRecipientName(e.target.value)}
    //     /> */}
    //     {/* <Form.Text className="text-muted"> */}
    //    {/* We'll never share your email with anyone else. */}
    //     {/* </Form.Text> */}
    //   {/* </Form.Group> */}

    //   {/* <Form.Control
    //     type="text"
    //     placeholder="number of clicks: 4 Clicks"
    //     aria-label="Disabled input example"
    //     readOnly
    //   /> */}
    //   </Form>
    //   <Button 
    //   style={{ fontFamily: 'Lexend Deca', fontSize: '25px', fontWeight: 80, marginTop: 'auto', borderRadius: 15, height: 80, width: '90%', margin: 30, backgroundColor:'#000000', color:'#FFFFFF' }}
    //     variant="light"
    //     onClick={handleShare}>S h a r e</Button>
    // </div>
  );
};

export default MessagePage;